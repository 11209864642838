import React, {useEffect, useState} from 'react'
import { fetchDashboardData } from './DashboardService'
import { useHasRole } from '../util/useHasRole'
import { useFilterContext } from '../context/filterContext'
import { useErrorHandler } from '../hooks/useErrorHandler'
import { useNavigate } from 'react-router-dom'

const Dashboard = () => {
    const navigate = useNavigate()
    const handleError = useErrorHandler()
    const {user,updateLoader} = useFilterContext()
    const [data,setData] = useState()
    const isSuperAdmin = useHasRole('super_admin')
    const isAdmin = useHasRole('admin')

    const getData = async() => {
        try {
            updateLoader(true)
            const result = await fetchDashboardData()
            if(result)
            {
                setData(result);
            }
        } catch (error) {
            handleError(error)
        }finally{
            updateLoader(false)
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const handleClick = (award) => {
        const dataToPass = {
            label: award.name,
            value: award.id
        }
        localStorage.setItem('data', JSON.stringify(dataToPass))
        navigate('/application/all') 
      
    }
    return (
        <>
        {
            isSuperAdmin || isAdmin ?
            <>
            
            <h3 className='mb-4'>Admin Dashboard</h3>
            <div className='row mb-3'>
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row shapreter-row">
                                <div className="col-sm-4 col-6 mb-3">
                                    <div className="static-icon">
                                        <span>
                                        <i className='bx bx-spreadsheet' ></i>
                                        </span>
                                        <h3 className="count mb-0">{data && data.total_applications}</h3>
                                        <p className="mb-0">Total Applications
                                        </p></div>
                                </div>
                                <div className="col-sm-4 col-6 mb-3">
                                    <div className="static-icon">
                                        <span>
                                        <i className='bx bx-spreadsheet' ></i>
                                        </span>
                                        <h3 className="count mb-0">{data && data.total_applications_pending}</h3>
                                        <p className="mb-0">Total Applications Pending</p>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-6 mb-3">
                                    <div className="static-icon">
                                        <span>
                                        <i className='bx bx-spreadsheet' ></i>
                                        </span>
                                        <h3 className="count mb-0">{data && data.total_applications_approved}</h3>
                                        <p className="mb-0">Total Applications Approved</p>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mb-3'>
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row shapreter-row">
                                <div className="col-sm-4 col-6 mb-3">
                                    <div className="static-icon">
                                        <span>
                                        <i className='bx bxs-grid-alt'></i>
                                        </span>
                                        <h3 className="count mb-0">{data && data.total_judges_applications}</h3>
                                        <p className="mb-0">Total Judges Applications
                                        </p></div>
                                </div>
                                <div className="col-sm-4 col-6 mb-3">
                                    <div className="static-icon">
                                        <span>
                                        <i className='bx bxs-grid-alt'></i>
                                        </span>
                                        <h3 className="count mb-0">{data && data.total_judges_applications_pending}</h3>
                                        <p className="mb-0">Total Judges Applications Pending</p>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-6 mb-3">
                                    <div className="static-icon">
                                        <span>
                                        <i className='bx bxs-grid-alt'></i>
                                        </span>
                                        <h3 className="count mb-0">{data && data.total_judges_applications_approved}</h3>
                                        <p className="mb-0">Total Judges Applications Approved</p>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mb-3'>
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row shapreter-row">
                                <div className="col-sm-4 col-6 mb-3">
                                    <div className="static-icon">
                                        <span>
                                        <i className='bx bxs-award' ></i>
                                        </span>
                                        <h3 className="count mb-0">{data && data.total_awards}</h3>
                                        <p className="mb-0">Total Awards
                                        </p></div>
                                </div>
                                <div className="col-sm-4 col-6 mb-3">
                                    <div className="static-icon">
                                        <span>
                                       <i className='bx bxs-user'></i>
                                        </span>
                                        <h3 className="count mb-0">{data && data.total_users}</h3>
                                        <p className="mb-0">Total Users</p>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-6 mb-3">
                                    <div className="static-icon">
                                        <span>
                                       <i className='bx bxs-user'></i>
                                        </span>
                                        <h3 className="count mb-0">{data && data.total_judges}</h3>
                                        <p className="mb-0">Total Judges</p>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row '>
                {
                    data && data.awards_application_count && data.awards_application_count.map(award => (
                        <div className='col-md-4 mb-3' key={award.id}>
                            <div className='card cursor-pointer' onClick={() => handleClick(award)}>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-8'>
                                            {award.name} Applications
                                        </div>
                                        <div className='col-4 text-end'>
                                            <span className='badge bg-primary1'>{award.applications_count}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            </>
            :
            <>
            <h3 className='mb-4'>Welcome {user.name}</h3>
             <div className='row mb-3'>
                <div className="col-sm-4 col-6 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="static-icon">
                                <span>
                                <i className='bx bx-spreadsheet' ></i>
                                </span>
                                <h3 className="count mb-0">{data && data.total_applied_applications}</h3>
                                <p className="mb-0">Total Applied Applications
                                </p>
                            </div>
                            
                        </div>
                    </div>
                </div>                
            </div>
            </>
          
        }
           
        </>
    )
}

export default Dashboard